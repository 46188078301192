import React, { useEffect }  from 'react'
import {MainWrapper} from './Ctm.styles'
import CtmBanner from "../../images/ctmBanner.png"
import {Container, Col, Row} from "react-bootstrap"
import { useAnimation, motion } from "framer-motion"
import { useInView } from "react-intersection-observer"
function Ctm() {
    const controls = useAnimation();
    const [ref, inView] = useInView();
  
    useEffect(() => {
      if (inView) {
        controls.start("visible");
      }
    }, [controls, inView]);


    return (
        <MainWrapper>
            <Container>
                <Row>
                 

                    <Col lg={6} md={6} sm={12} xs={12}>
                    <motion.div class="vertical_center banner-text" 
                    ref={ref}
                    animate={controls}
                    initial="hidden"
                    variants={{visible: { opacity: 1, x: 0, transition: { duration: 1 } },
                    hidden: { opacity: 0, x: -50 }}}>
                   
                       <h1>
                       A GLOBAL DIGITAL RISK PROTECTION PLATFORM
                        </h1>
                        <p>
                        <strong>CTM360®</strong> identifies vulnerabilities, and detects, manages and responds to threats in the <strong>Surface, Deep</strong> & <strong>Dark web</strong>. 
                        </p>
                        <p>Offered as a fully managed technology platform in the cloud, CTM360® is outside the perimeter of an organization</p>
                    </motion.div>
                    </Col>
                    <Col lg={6} md={6} sm={12} xs={12}>
                    <img src={CtmBanner}/>
                    </Col>
                </Row>
                
            </Container>
        </MainWrapper>
    )
}

export default Ctm
