import React, { useEffect } from "react"
import { StackWrapper } from "./Ctm.styles"
import { Container, Col, Row } from "react-bootstrap"
import { useAnimation, motion } from "framer-motion"
import { useInView } from "react-intersection-observer"
import StackImage from "../../images/digital-risk-protection-stack.jpg"


function DrpStack() {
  const controls = useAnimation()
  const [ref, inView] = useInView()

  useEffect(() => {
    if (inView) {
      controls.start("visible")
    }
  }, [controls, inView])

  return (
    <StackWrapper>
      <Container>
        <Row>
          <Col lg={6} md={6} sm={12} xs={12}>
            <motion.div className="vertical_center"
              ref={ref}
              animate={controls}
              initial="hidden"
              variants={{
                visible: { opacity: 1, x: 0, transition: { duration: 1 } },
                hidden: { opacity: 0, x: -150 },
              }}
            >
              <h2>CTM360’S DIGITAL RISK PROTECTION STACK</h2>
              <a href="/contact">Contact us to find out more</a>
            </motion.div>
          </Col>
          <Col lg={6} md={6} sm={12} xs={12}>
            <motion.div
              ref={ref}
              animate={controls}
              initial="hidden"
              variants={{
                visible: { opacity: 1, x: 0, transition: { duration: 1 } },
                hidden: { opacity: 0, x: 150 },
              }}
            >
              <img src={StackImage}></img>
            </motion.div>
          </Col>
        </Row>
       
      </Container>
    </StackWrapper>
  )
}

export default DrpStack
