import * as React from "react"
import { Link } from "gatsby"
import 'bootstrap/dist/css/bootstrap.min.css';

import Layout from "../components/Layout/Layout"
import Seo from "../components/seo";
import Ctm from "../components/Ctm";
import CtmOffers from "../components/Ctm/CtmOffers";
import DrpStack from "../components/Ctm/DrpStack";
import CtmPlatform from "../components/Ctm/CtmPlatform";
import CtmTestimonials from "../components/Ctm/CtmTestimonials";
import CallToAction from "../components/CallToAction/index"

const IndexPage = () => (
  <Layout>
    <Seo title="CTM360" />
    <Ctm/>
    <CtmOffers/>
    <DrpStack/>
    <CtmPlatform/>
    <CtmTestimonials/>
    <CallToAction title="Secure your Digital Presence in the Cloud" linktext="Contact Us" url="/contact"/>
  </Layout>
)

export default IndexPage
