import React, { useEffect, useState } from "react"
import { SliderWrapper } from "./Ctm.styles"
import { Container, Col, Row, Carousel } from "react-bootstrap"
import Gartner from "../../images/gartner_peer_insights.png"
import Awards from "../../images/awards-full.png"
import { FaQuoteLeft } from "@react-icons/all-files/fa/FaQuoteLeft"
import Marquee from "react-fast-marquee";

function CtmTestimonials() {
    const [index, setIndex] = useState(0);

    const handleSelect = (selectedIndex, e) => {
      setIndex(selectedIndex);
    };

    
  return (
    <div>
      <SliderWrapper>
        <Container>
        <Row className="awards-section-wrap">
        <h2>AWARDS & RECOGNITION</h2>
        <Col lg={12} md={12} sm={12} xs={12}>
            <Marquee><img src={Awards}></img></Marquee>
        </Col>
        </Row>
           
          <Row>
          <h2>TESTIMONIALS</h2>
            <Col lg={8} md={8} sm={12} xs={12}>
            <FaQuoteLeft/>
            <Carousel activeIndex={index} onSelect={handleSelect}>
              
      <Carousel.Item>
     
       
          <h3>Review of Brand Protection service of CTM360
We have been with CTM360 for 9 years now and i can say their service is very valuable for us. I highly recommend them</h3>
          <p>CIO</p>
          <span>Financial Industry</span>
        
      </Carousel.Item>
      <Carousel.Item>
        

          <h3>Reliable Cyber Threat Intelligence provider with multiple value adds included</h3>
          <p>CxO</p>
          <span>Financial Industry</span>
      </Carousel.Item>
      <Carousel.Item>
        

        
          <h3>One would be naïve not to consider CTM360 as their Digital Risk Protection Partner. It has been a wonderful working experience...</h3>
          <p>
            CIO
          </p>
          <span>Financial Industry</span>
      </Carousel.Item>
      <Carousel.Item>
        

        
        <h3>CTM360 DRP - Always available and very responsive. From an external security perspective, CTM360 is specialist in its domain of digital risks and threats. They are fully managed. </h3>
        <p>
          CIO
        </p>
        <span>Financial Industry</span>
    </Carousel.Item>
    </Carousel>
            </Col>
            <Col lg={4} md={4} sm={12} xs={12} className="vertical_center">
<a href="https://www.gartner.com/reviews/market/security-threat-intelligence-services/vendor/ctm360/product/ctm360/reviews" target="_blank">
                <img src={Gartner}></img>
                </a>
            </Col>
          </Row>
        </Container>
      </SliderWrapper>
    </div>
  )
}

export default CtmTestimonials
