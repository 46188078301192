import React, { useEffect } from "react"
import { OfferWrapper } from "./Ctm.styles"
import { Container, Col, Row } from "react-bootstrap"
import { useAnimation, motion } from "framer-motion"
import { useInView } from "react-intersection-observer"

import Icon1 from "../../images/external-attack.png"
import Icon2 from "../../images/brand-protection.png"
import Icon3 from "../../images/cyber-threat-intelligence.png"
import Icon4 from "../../images/takedown.png"
import Icon5 from "../../images/cloud-threat-hunting.png"
import Icon6 from "../../images/data-leakage.png"
import Icon7 from "../../images/ant-fraud.png"
import Icon8 from "../../images/cyber-security.png"

function CtmOffers() {
  const controls = useAnimation()
  const [ref, inView] = useInView()

  useEffect(() => {
    if (inView) {
      controls.start("visible")
    }
  }, [controls, inView])

  return (
    <OfferWrapper>
      <Container>
        <Row>
          <Col lg={12} md={12} sm={12} xs={12}>
            <motion.div
              ref={ref}
              animate={controls}
              initial="hidden"
              variants={{
                visible: { opacity: 1, y: 0, transition: { duration: 1 } },
                hidden: { opacity: 0, y: 50 },
              }}
            >
              <h2>WHAT CTM360 OFFERS</h2>
              <p>Through one consolidated platform, CTM360® offers</p>
              
            </motion.div>
          </Col>
        </Row>
        <Row>
          <Col lg={3} md={3} sm={6} xs={12} className="flip-row">
            <div className="flip-card ">
              <div className="flip-card-inner">
                <div className="flip-card-front">
                  <img src={Icon1} />
                  <h3>External Attack Surface Management</h3>
                </div>

                <div className="flip-card-back">
                  <p>
                    Enables your team to reduce an attacker’s visibility into
                    the organization whilst assisting you in enhancing control
                    over your cyber assets .
                  </p>
                </div>
              </div>
            </div>
          </Col>
          <Col lg={3} md={3} sm={6} xs={12} className="flip-row">
            <div className="flip-card ">
              <div className="flip-card-inner">
                <div className="flip-card-front">
                  <img src={Icon2} />
                  <h3>Brand Protection & Anti - Phishing</h3>
                </div>

                <div className="flip-card-back">
                  <p>
                 Offer to protect and prevent brand infringement, and potential impersonations to your brand or executive management.
                  </p>
                </div>
              </div>
            </div>
          </Col>
          <Col lg={3} md={3} sm={6} xs={12} className="flip-row">
            <div className="flip-card ">
              <div className="flip-card-inner">
                <div className="flip-card-front">
                  <img src={Icon3} />
                  <h3>Cyber Threat Intelligence</h3>
                </div>

                <div className="flip-card-back">
                  <p>
                  Simplify the process with our fully automated and highly specific threat intelligence, which provides detection on an on-going basis.
                  </p>
                </div>
              </div>
            </div>
          </Col>
          <Col lg={3} md={3} sm={6} xs={12} className="flip-row">
            <div className="flip-card ">
              <div className="flip-card-inner">
                <div className="flip-card-front">
                  <img src={Icon4} />
                  <h3>Takedown++</h3>
                </div>

                <div className="flip-card-back">
                  <p>
                    Offering many response actions leveraged by our various relationships with major registrars, memberships and global partnerships. 
                  </p>
                </div>
              </div>
            </div>
          </Col>
          <Col lg={3} md={3} sm={6} xs={12} className="flip-row">
            <div className="flip-card ">
              <div className="flip-card-inner">
                <div className="flip-card-front">
                  <img src={Icon5} />
                  <h3>Cloud Threat Hunting</h3>
                </div>

                <div className="flip-card-back">
                  <p>
                  Via pivoting and playbooks, we greatly reduce the burden from your team and offer cloud threat hunting as a standard feature of our bundled platform and managed services offering.
                  </p>
                </div>
              </div>
            </div>
          </Col>
          <Col lg={3} md={3} sm={6} xs={12} className="flip-row">
            <div className="flip-card ">
              <div className="flip-card-inner">
                <div className="flip-card-front">
                  <img src={Icon6} />
                  <h3>Data Leakage Protection</h3>
                </div>

                <div className="flip-card-back">
                  <p>
                  Keeps you up to date with the latest data leaks on a global scale and alerts of any critical situations.  
                  </p>
                </div>
              </div>
            </div>
          </Col>
          <Col lg={3} md={3} sm={6} xs={12} className="flip-row">
            <div className="flip-card ">
              <div className="flip-card-inner">
                <div className="flip-card-front">
                  <img src={Icon7} />
                  <h3>Online Anti-Fraud</h3>
                </div>

                <div className="flip-card-back">
                  <p>
                  Comprehensive detection and response on all variations of such scams & frauds across cyberspace
                  </p>
                </div>
              </div>
            </div>
          </Col>
          <Col lg={3} md={3} sm={6} xs={12} className="flip-row">
            <div className="flip-card ">
              <div className="flip-card-inner">
                <div className="flip-card-front">
                  <img src={Icon8} />
                  <h3>Cyber Security Awareness</h3>
                </div>

                <div className="flip-card-back">
                  <p>
                    Offering everyone involved and informed on security and new threats.
                  </p>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </OfferWrapper>
  )
}

export default CtmOffers
