import React, { useEffect, useState } from "react"
import { PlatformWrapper } from "./Ctm.styles"
import { Container, Col, Row, Tabs, Tab } from "react-bootstrap"
import { useAnimation, motion } from "framer-motion"
import { useInView } from "react-intersection-observer"
import Dashboard1 from "../../images/cyber-blindspot.png"
import Dashboard2 from "../../images/hacker-view-dashboard.jpg"
import Dashboard3 from "../../images/cyber-threat-intelligence (1).png"

function CtmPlatform() {
  const [key, setKey] = useState("CyberBlindspot")

  const controls = useAnimation()
  const [ref, inView] = useInView()

  useEffect(() => {
    if (inView) {
      controls.start("visible")
    }
  }, [controls, inView])

  return (
    <PlatformWrapper>
      <Container>
        <Row>
          <Col lg={12} md={12} sm={12} xs={12}>
            <motion.div
              className="vertical_center"
              ref={ref}
              animate={controls}
              initial="hidden"
              variants={{
                visible: { opacity: 1, x: 0, transition: { duration: 1 } },
                hidden: { opacity: 0, x: -150 },
              }}
            >
              <h2>OUR CONSOLIDATED PLATFORM</h2>
            </motion.div>
          </Col>
          <Col lg={12} md={12} sm={12} xs={12}>
            <motion.div
              ref={ref}
              animate={controls}
              initial="hidden"
              variants={{
                visible: { opacity: 1, x: 0, transition: { duration: 1 } },
                hidden: { opacity: 0, x: 150 },
              }}
            >
              <Tabs
                id="controlled-tab-example"
                activeKey={key}
                onSelect={k => setKey(k)}
                className="mb-3"
              >
                <Tab eventKey="CyberBlindspot" title="CyberBlindspot">
                  <div className="tab-content-text">
                    <p>Targeted Threat Intelligence & Incident Management</p>
                    <img src={Dashboard1}></img>
                  </div>
                </Tab>
                <Tab eventKey="HackerView" title="HackerView">
                  <div className="tab-content-text">
                    <p>External Attack Surface Management</p>
                    <img src={Dashboard2}></img>
                  </div>
                </Tab>
                <Tab eventKey="ThreatCover" title="ThreatCover">
                  <div className="tab-content-text">
                    <p>Global Cyber Threat Intelligence</p>
                    <img src={Dashboard3}></img>
                  </div>
                </Tab>
              </Tabs>
            </motion.div>
            <a href="/request-a-demo">Request a demo</a>
          </Col>
        </Row>
      </Container>
    </PlatformWrapper>
  )
}

export default CtmPlatform
