import styled from "styled-components"

export const MainWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  padding-top: 120px;
  padding-bottom: 20px;

  h1 {
    color: #2ecb77;
    text-transform: uppercase;
    font-size: 38px;
    font-weight: 600;
  }
  p {
    font-size: 18px;
    line-height: 25px;
    color: #021737;
    width: 100%;
  }

  .vertical_center {
    align-self: center;
    height: 100%;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: center;
  }
`
export const OfferWrapper = styled.div`
  h2 {
    color: #003283;
    font-size: 30px;
    font-weight: 600;
  }
  p {
    font-size: 18px;
    line-height: 25px;
    color: #021737;
    width: 100%;
  }
  .card-body {
    cursor: pointer;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 var(--bluish-green);
  }
  .flip-row {
    margin: 15px 0;
  }

  .flip-card {
    background-color: transparent;
    width: 100%;
    min-height: 200px;
    height: 100%;
    perspective: 1000px;
  }

  .flip-card-inner {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    transition: transform 0.6s;
    transform-style: preserve-3d;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  }

  .flip-card:hover .flip-card-inner {
    transform: rotateY(180deg);
  }

  .flip-card-front,
  .flip-card-back {
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
  }

  .flip-card-front {
    box-shadow: 1px 6px 10px -6px #003283;
    border-radius: 8px;
    border-top: 1px solid #0032832e;
    padding: 25px 15px;
  }

  .flip-card-front img {
    max-width: 30%;
    filter: hue-rotate(-246deg) brightness(1.9);
    @media (max-width: 991px) {
      max-width: 18%;
    }
  }

  .flip-card-back {
    background-color: #003283;
    color: #f1f1f1;
    transform: rotateY(180deg);
    border-radius: 8px;
    padding: 25px 15px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .flip-card-front h3 {
    color: #021737;
    font-size: 22px;
    font-weight: 500;
  }

  .flip-card-back p {
    color: white;
    font-size: 14px;
    margin: 0;
  }
`

export const StackWrapper = styled.div`
  padding: 25px 0;
  h2 {
    color: #003283;
    text-align: left;
    font-size: 40px;
    font-weight: 600;
  }
  a {
    background-color: transparent;
    color: #003283 !important;
    border: 2px solid #003283;
    border-radius: 25px;
    padding: 8px 25px !important;
    font-size: 14px !important;
    font-weight: 700 !important;
    text-transform: uppercase;

    :hover {
      background-color: #00e66e;
    }
  }
  .vertical_center {
    align-self: center;
    height: 100%;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: center;
  }
`

export const PlatformWrapper = styled.div`
background-color: #003283;
padding: 50px 0;
h2{
  color: #fff;
    text-align: center;
    font-size: 30px;
    font-weight: 600;
    margin-bottom: 25px;
}
a{
  background-color: transparent;
    color: #fff !important;
    border: 2px solid #f9f9f9;
    border-radius: 25px;
    padding: 8px 25px !important;
    font-size: 14px !important;
    font-weight: 700 !important;
    text-transform: uppercase;
    display: flex;
    align-self: center;
    max-width: 200px;
    margin: 0 auto;
    justify-content: center;
    text-decoration: none;
    margin-top: 30px;

    :hover{
      
    background-color: #00E66E;
    }
}
.nav{
  justify-content: center;
  border-bottom: 1px solid #032762;
   .nav-link {
    background: #002c74;
    color: #fff;
    margin: 0 8px;
}


}
.tab-content-text{
  padding-top: 20px;
  p{
    text-align: center;
    color: #f1f1f1;
  }
  img{
    max-width: 800px;
    margin: 0 auto;
    display: block;
    border: 6px solid #012662;
    border-radius: 10px;
  }
}
`
export const SliderWrapper = styled.div`
padding: 80px 0;
margin-bottom: 30px;
.awards-section-wrap{
  margin-bottom: 30px;
}
  h2 {
    color: #003283;
    text-align: left;
    font-size: 30px;
    font-weight: 600;
  }
  svg{
    color: #777;
    margin: 0 auto;
    display: BLOCK;
    font-size: 45px;
    opacity: 0.5;
  }
.carousel {
  padding: 0 6%;
  margin-top:30px;

.carousel-control-prev {
  left: 0;
  width: auto;
  opacity: 1;
  span{
    background-color: #003283;
  }
}
.carousel-control-next {
  right: 0;
  width: auto;
  opacity: 1;
  span{
    background-color: #003283;
  }
}
h3 {
  color: #021737;
  font-weight: 500;
  font-size: 18px;
  margin-bottom: 10px;
  line-height: 30px;
}
p{
    color: #021737;
    font-weight: 600;
    font-size: 15px;
    margin-bottom: 0;
    line-height: 20px;
    text-align: center;

}
span{
  text-align: center;
  display: block;
}
}

.vertical_center {
  align-self: center;
  height: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
`